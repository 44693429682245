const IconSearchAlternative = () => (
    <svg fill="none" viewBox="0 0 22 24" xmlns="http://www.w3.org/2000/svg">
        <g strokeWidth="2">
            <path
                d="m13.2637 14.3691 7.7368 7.7369"
                stroke="#feca01"
                strokeLinecap="round"
            />
            <circle cx="8.2895" cy="8.2895" r="7.2895" stroke="#005b75" />
        </g>
    </svg>
);

export default IconSearchAlternative;
